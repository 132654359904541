module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"AxonDivision","short_name":"Axon","start_url":"/","description":"AxonDivision is a full-service digital agency based in Dublin. We help you and your business succeed in this great acceleration of digitalisation with our unique blend of expertise in design, data and technology.","background_color":"#ffffff","theme_color":"#141316","display":"minimal-ui","lang":"en","icon":"src/favicon/@icons/icon-round.png","icons":[{"src":"src/favicon/favicon-16x16.png","type":"image/png","sizes":"16x16"},{"src":"src/favicon/favicon-32x32.png","type":"image/png","sizes":"32x32"},{"src":"src/favicon/@icons/icon-round.png","type":"image/png","sizes":"512x512"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"05b49f4aeb6756b43616a9915b2ce18d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/henrique/Valet/axon"},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"typekit":{"id":"xxxxxxx"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[{"resolve":"/Users/henrique/Valet/axon/node_modules/gatsby-plugin-linkedin-insight","id":"820ce490-140a-5265-9605-1d3783daba10","name":"gatsby-plugin-linkedin-insight","version":"1.0.1","pluginOptions":{"plugins":[],"partnerId":"4048809","includeInDevelopment":false},"nodeAPIs":[],"browserAPIs":[],"ssrAPIs":["onRenderBody"]}],"configDir":"gatsby","projectRoot":"/Users/henrique/Valet/axon"},
    }]
