// import { loadScript } from "./utils";

import { asyncPrependToContainer, makeElement } from "../utils/dom.utils";
import { log } from "../utils/loggers.utils";

// Google Tag Manager

export const LOAD_GTAG = (id: string, onLoad?: () => void) => {
  log("LOAD_GTAG", id);

  const script = document.createElement("script");
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
  script.onload = () => {
    window.dataLayer = window.dataLayer || [];

    function gtag(...args: any[]) {
      window.dataLayer.push(...args);
    }

    // @ts-ignore
    window.gtag = gtag;

    gtag("js", new Date());
    gtag("config", id);

    onLoad?.();
  };

  document.head.appendChild(script);
};